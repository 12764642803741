@use '../custom' as *;

.about {
    padding-bottom: 30px;
  
    .about .container {
        box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    }
    
    .about .video-box img {
        padding: 15px 0;
    }
    
    .about .section-title p {
        text-align: left;
        font-style: italic;
        color: #666;
    }
    
    .about .about-content {
        padding: 40px;
    }
}