:root {
	--bs-orange: #F46602;
	--bs-green: #81B33A;
}
/* html {
	font-size: 62.5%;
} */
/* body
{
	font-family: 'Raleway', sans-serif;
	font-size: 1.6rem;
	color: #777;
	line-height: 2;
} */
/* body, html {
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
} */

/** CAMBIO DE COLORES*/
.text-primary {
	color: var(--bs-orange) !important;
}
.bg-primary,
.btn-prymary {
	background-color: var(--bs-orange) !important;
}
.text-success {
	color: var(--bs-green) !important;
}
.bg-success {
	background-color: var(--bs-green) !important;
}

/** h1 h2 h3 ... */
h1, h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
}
h1 {
	font-size: 3.5rem;
}
h2 {
	font-weight: 600;
	font-size: 3.1rem;
	color: #333;
}
h3 {
	font-weight: 600;
	font-size: 2.4rem;
	color: #333;
}
@media (min-width: 768px) {
	h1 {
		font-size: 5rem;
	}
	h2 {
		font-size: 4rem;
	}
	h3 {
		font-size: 3rem;
	}
}
h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}

/* ******************* NAVBAR ******************* */
.sticky-top-2 {
	/* position: -webkit-sticky; */
	position: sticky;
	top: 0;
	z-index: 1030;
}
.container-fluid {
	height: 350px;
}
/* ******************** HERO ******************** */
/* .hero {
	background-image: url(../assets/img/bg-aic.jpg);
	height: 30rem;
	background-size: cover;
	background-position: center center;
}
@media (min-width: 576px) {
	.hero {
		height: 40rem;
	}
}
@media (min-width: 768px) {
	.hero {
		height: 55rem;
	}
}
@media (min-width: 1024px) {
	.hero {
		height: 75rem;
	}
}
@media (min-width: 1400px) {
	.hero {
		height: 97rem;
	}
} */

/* ***************** PORTAFOLIO ***************** */
/* .imag {
	height: 27rem;
	width: 100%;
	object-fit: cover;
} */

/* ******************** SERVICE ******************** */
/* .services {
	background-image: url(../assets/img/services.png);
	height: 20rem;
	background-size: cover;
	background-position: center center;
}
@media (min-width: 576px) {
	.services {
		height: 80rem;
	}
} */

/* ********************* CONTACT ********************* */
.box-contact {
	background-color:#81B33A;
	min-height: 24rem;
	align-items: center;
}


/* @media (max-width: 768px) {
#about img {
	margin: 50px 0;
}
}  */