@use "../custom" as *;

.services {
  padding-bottom: 30px;
}

.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: flex;
  justify-content: center;
}

.services .icon i {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: $primary;
  font-size: 40px;
  overflow: hidden;
  padding-top: 5px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}

.services .icon-box:hover .icon i {
  box-shadow: 0px 0 30px $primary;
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444;
  transition: 0.3s;
}

.services .title a:hover {
  color: #428bca;
}

.services .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: $primary;
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}
