// Reescribir variables de bootstrap
$naranja: #f46602;
$verde: #81b33a;
$oscuro: #777777;

// Asignar paleta de colores a Bootstrap
$primary: $naranja;
$secondary: $verde;
$dark: $oscuro;

// Importar Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
