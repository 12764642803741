@use '../custom' as *;

body
{
	font-family: 'Raleway', sans-serif;
	color: #777;
	line-height: 2;
}

a {
    color: #777;
    text-decoration: none;
  }
  
  a:hover {
    color: $primary;
    text-decoration: none;
  }