@use "../custom" as *;

.info-box {
  color: #444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  margin-bottom: 30px;
  width: 100%;
  .info-box i {
    font-size: 32px;
    color: #428bca;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #9eccf4;
  }

  .info-box h3 {
    font-size: 20px;
    color: #666;
    font-weight: 700;
    margin: 10px 0;
  }

  .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }

  //   .info-box {
  //     padding-bottom: 30px;
  //   }

  //   .info-box .icon-box {
  //     margin-bottom: 20px;
  //     text-align: center;
  //   }

  .icon {
    display: flex;
    justify-content: center;
  }

  .icon i {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: $primary;
    font-size: 40px;
    overflow: hidden;
    padding-top: 5px;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
  }
}

.form-box {
  color: #444;
  //   text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 030px 30px 30px;
  margin-bottom: 30px;
  width: 100%;
}
